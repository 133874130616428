import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { BsArrowRightShort } from "react-icons/bs";
import { MdMail, MdPhone } from "react-icons/md";
import ContactForm from "../components/contactForm";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { Button } from "react-bootstrap";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			pageData: wpPage(slug: { eq: "contact" }) {
				title
				pageFlexibleContent {
					pageFlexibleContent {
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner {
							fieldGroupName
							innerPageBannerHeading
							innerPageBannerSubHeading
							innerPageLink {
								target
								title
								url
							}
							innerPageBannerImage {
								sourceUrl
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_GetInTouch {
							fieldGroupName
							getInTouchHeading
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_ContactInfoSection {
							fieldGroupName
							contactInfoNumber
							contactInfoEmail
							contactInfoAddress
							contactInfoNotice
							contactScheduleLink {
								target
								title
								url
							}
							contactCards {
								contactCardHeading
								contactCardLink {
									target
									title
									url
								}
								contactCardIcon {
									sourceUrl
								}
							}
							mapIframe
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	const {
		pageData: {
			pageFlexibleContent: { pageFlexibleContent },
		},
	} = data;

	const bannerSection = () => {
		if (pageFlexibleContent.length > 0) {
			const bannerSection = pageFlexibleContent.filter(
				(item) =>
					item.fieldGroupName ==
					"Page_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner"
			);

			if (bannerSection.length > 0) {
				return (
					<Hero
						title={
							<span
								dangerouslySetInnerHTML={{
									__html: bannerSection[0].innerPageBannerHeading,
								}}
							></span>
						}
						subHeading={bannerSection[0].innerPageBannerSubHeading}
						buttonTarget={bannerSection[0].innerPageLink?.target}
						buttonLink={bannerSection[0].innerPageLink?.url}
						buttonTitle={bannerSection[0].innerPageLink?.title}
						bannerImage={bannerSection[0].innerPageBannerImage.sourceUrl}
						hideButton={!bannerSection[0].innerPageLink ?? true}
					/>
				);
			}
		}
	};

	const getInTouch = () => {
		let heading = "Get in touch to find out how we can work together";
		if (pageFlexibleContent.length > 0) {
			const reviewSection = pageFlexibleContent.filter(
				(item) =>
					item.fieldGroupName ==
					"Page_Pageflexiblecontent_PageFlexibleContent_GetInTouch"
			);
			if (reviewSection.length > 0) {
				heading = reviewSection[0].getInTouchHeading;
			}
		}
		return (
			<section style={{ zIndex: 3 }} className="pb-5 py-lg-7 position-relative">
				<Container>
					<Row>
						<Col>
							<h2 className="fs-1 text-center mb-cl-5 mb-4">{heading}</h2>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={8}>
							<ContactForm />
						</Col>
					</Row>
				</Container>
			</section>
		);
	};

	const contactInfoSection = () => {
		if (pageFlexibleContent.length > 0) {
			const contactInfo = pageFlexibleContent.filter(
				(item) =>
					item.fieldGroupName ==
					"Page_Pageflexiblecontent_PageFlexibleContent_ContactInfoSection"
			);

			if (contactInfo.length > 0) {
				return (
					<section id="results" className="pb-5 py-lg-7  h-100 ">
						<Container className="">
							<Row className="g-5 h-100">
								<Col className="py-lg-5" lg={6}>
									{/* <div className="d-flex align-items-center pb-3">
									  <MdPhone className="text-primary me-2 fs-3" />
									  <a href="tel:02033227261" className="white-link fs-4 me-4">
										  London: 02033 227 261
									  </a>
								  </div> */}
									<div className="d-flex align-items-center pb-3">
										<MdPhone className="text-primary me-2 fs-3" />
										<a
											href={`tel:${contactInfo[0].contactInfoNumber}`}
											className="white-link fs-4 me-4"
										>
											Sussex: {contactInfo[0].contactInfoNumber}
										</a>
									</div>
									<div className="d-flex align-items-center pb-3">
										<MdMail className="text-primary me-2 fs-3" />
										<a
											href={`mailto:${contactInfo[0].contactInfoEmail}`}
											className="white-link fs-4"
										>
											{contactInfo[0].contactInfoEmail}
										</a>
									</div>
									<div
										dangerouslySetInnerHTML={{
											__html: contactInfo[0].contactInfoAddress,
										}}
									></div>
									<div
										dangerouslySetInnerHTML={{
											__html: contactInfo[0].contactInfoNotice,
										}}
									></div>
									{/* <div className="d-flex align-items-center pb-3">
						<BsHeadset className="text-white me-2  fs-4" />
						<a
							target="_blank"
							rel="noreferrer"
							href="#"
							className="white-link me-2"
						>
							Remote session
						</a>
					</div>
					<div className="d-flex align-items-center pb-3">
						<HiOutlineTicket className="text-white me-2  fs-4" />
						<a
							target="_blank"
							rel="noreferrer"
							href="#"
							className="white-link "
						>
							Raise ticket
						</a>
					</div>
					<div className="d-flex align-items-center ">
						<img alt="Emergency icon" className=" me-2" src={Emergency} />
						<a href="tel:02033227261" className="white-link ">
							Emergency call
						</a>
					</div> */}
									{contactInfo[0].contactCards.length > 0 && (
										<Row className="gx-2 gy-3">
											{contactInfo[0].contactCards.map((contactDetail, i) => (
												<Col md={4} lg={12} xl={4} key={i}>
													<div
														style={{
															zIndex: 2,
															borderRadius: "4px",
															border: "1px solid #4FC4D8",
														}}
														className="bg-black pt-2 ps-3 pb-2 position-relative"
													>
														<div
															style={{ zIndex: 3 }}
															className="position-absolute pb-2 end-0 bottom-0"
														>
															<img
																alt="Headphones icon"
																src={contactDetail.contactCardIcon.sourceUrl}
															/>
														</div>
														<div
															style={{ zIndex: 4 }}
															className="position-relative"
														>
															<h3 className="pb-2 fs-6 ">
																{contactDetail.contactCardHeading}
															</h3>
															<div className="d-flex align-items-center">
																<a
																	style={
																		contactDetail["contactCardHeading"] !==
																			"Raise ticket" && { fontSize: "90%" }
																	}
																	target={
																		contactDetail.contactCardLink?.target ??
																		"_blank"
																	}
																	rel="noreferrer"
																	href={contactDetail.contactCardLink?.url}
																	className="blue-link roboto-medium"
																>
																	{contactDetail.contactCardLink?.title ??
																		"Start now"}
																	<BsArrowRightShort className="fs-2" />
																</a>
															</div>
														</div>
													</div>
												</Col>
											))}
										</Row>
									)}

									<div className="text-center">
										<Button
											target={
												contactInfo[0].contactScheduleLink.target ?? "_blank"
											}
											rel="noreferrer"
											href={
												contactInfo[0].contactScheduleLink.url ??
												"https://outlook.office.com/bookwithme/user/1386c77a23fa477c8c1f8232294da53c@cranfielditsolutions.co.uk/meetingtype/OHHZY3rg40-mG-U4B1-gjw2?anonymous&ep=mLinkFromTile"
											}
											variant="primary"
											className="px-md-5 mt-5 mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto"
										>
											{contactInfo[0].contactScheduleLink.title ??
												"Schedule in our calendar"}
										</Button>
									</div>
								</Col>
								<Col
									style={{ minHeight: "100%" }}
									lg={6}
									dangerouslySetInnerHTML={{
										__html: contactInfo[0].mapIframe,
									}}
								></Col>
							</Row>
						</Container>
					</section>
				);
			}
		}
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Contact Us | Cranfield IT Solutions"
				description="Get in touch to find out how we can work together."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Us | Cranfield IT Solutions",
					description: "Get in touch to find out how we can work together.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{bannerSection()}
				{getInTouch()}
				{contactInfoSection()}
			</Layout>
		</>
	);
};

export default ContactUsPage;
